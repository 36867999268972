import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import fetch from 'unfetch';

import { MerchantContactQuery } from '@lib/types';
import { Wrapper, SEO, Circle, Button } from '@components';

import * as s from './_styles';

const STATUS = {
    DEFAULT: 'DEFAULT',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    WAITING: 'WAITING',
};

const ContactPage = () => {
    const { allCloverMerchant } = useStaticQuery<MerchantContactQuery>(graphql`
        {
            allCloverMerchant {
                nodes {
                    address {
                        address1
                        city
                        phoneNumber
                        state
                        zip
                    }
                }
            }
        }
    `);
    const [status, setStatus] = useState(STATUS.DEFAULT);
    const [state, setState] = useState({
        'form-name': 'contact',
        'bot-field': '',
        name: '',
        email: '',
        message: '',
    });
    const { text: buttonText, color: buttonColor } = {
        [STATUS.DEFAULT]: { text: 'Submit', color: '#388e3c' },
        [STATUS.ERROR]: { text: 'Woops. Please try again.', color: '#ad2c2c' },
        [STATUS.SUCCESS]: { text: 'Thanks for your feedback', color: '#388e3c' },
        [STATUS.WAITING]: { text: <div css={s.spinner} />, color: '#388e3c' },
    }[status];

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setState({ ...state, [event.target.name]: event.target.value });

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if ([STATUS.SUCCESS, STATUS.WAITING].includes(status)) {
            return;
        }

        setStatus(STATUS.WAITING);

        grecaptcha.ready(() => {
            grecaptcha.execute(window.GRECAPTCHA_KEY as string, { action: 'contact' }).then((token: string) => {
                const body = Object.entries({ ...state, 'g-recaptcha-response': token })
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&');

                fetch('/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body,
                }).then(({ status: statusCode }) => {
                    if (statusCode === 200) {
                        setStatus(STATUS.SUCCESS);
                        setState({
                            ...state,
                            name: '',
                            email: '',
                            message: '',
                        });
                    } else {
                        setStatus(STATUS.ERROR);
                    }
                });
            });
        });
    };

    return (
        <Wrapper>
            <SEO title="Contact Us" keywords={[`gatsby`, `application`, `react`]} />
            <h1 css={s.title}>Contact Us</h1>
            <div css={s.content}>
                <aside css={s.side}>
                    {allCloverMerchant.nodes.map(({ address }) => (
                        <Circle location={address} key={address.address1} />
                    ))}
                </aside>
                <div>
                    <p css={s.paragraph}>
                        Have a question, concern, or any sort of feedback? We value any feedback and look forward to
                        hearing from you.
                    </p>
                    <form
                        css={s.form}
                        name="contact"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        data-netlify-recaptcha="true"
                        onSubmit={handleFormSubmit}
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <label htmlFor="bot-field">
                            Don’t fill this out if you&apos;re human:{' '}
                            <input
                                id="bot-field"
                                name="bot-field"
                                value={state['bot-field']}
                                onChange={handleInputChange}
                            />
                        </label>
                        <label css={s.label} htmlFor="name">
                            <span>Name</span>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={state.name}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <label css={s.label} htmlFor="email">
                            <span>Email</span>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={state.email}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <label css={s.label} htmlFor="message">
                            <span>Message</span>
                            <textarea
                                name="message"
                                id="message"
                                value={state.message}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <div css={s.submitWrapper}>
                            <Button
                                type="submit"
                                id={typeof buttonText === 'string' ? buttonText : status}
                                color={buttonColor}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default ContactPage;
