import { css, keyframes } from '@emotion/react';

export const title = css`
    color: #ffb300;
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 4rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.15em;
        left: 0.4em;
        width: 1.2em;
        height: 0.3em;
        background-image: radial-gradient(circle, #ffb300 35%, transparent 0);
        background-size: 0.3em 0.3em;
        background-repeat: repeat-x;
        background-position: 0 0;
    }
`;

export const content = css`
    display: flex;
    flex-direction: column;

    @media (min-width: 53rem) {
        flex-direction: row;
        align-items: stretch;
    }
`;

export const side = css`
    padding-top: 2rem;
    padding-left: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 18rem;
    color: white;
    text-align: center;
    order: 2;

    & > :first-of-type,
    & > :last-of-type {
        max-width: 12rem;
    }

    & > :last-of-type {
        margin-top: -1.5rem;
        margin-left: 4rem;
    }

    @media (min-width: 26rem) {
        padding-top: 0;
        flex-direction: row;
        align-items: flex-start;

        & > :first-of-type,
        & > :last-of-type {
            flex: 0 0 12rem;
        }

        /* & > :first-of-type {
        top: -1rem;
    } */

        & > :last-of-type {
            margin-left: 2rem;
            margin-top: 2rem;
        }
    }
    @media (min-width: 53rem) {
        flex-direction: column;
        order: 0;

        & > :first-of-type,
        & > :last-of-type {
            flex: 1 1 100%;
        }

        & > :first-of-type {
            margin-left: -2rem;
        }

        & > :last-of-type {
            margin-right: -2rem;
            margin-top: -1.5rem;
        }
    }
`;

export const paragraph = css`
    margin-left: auto;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 1rem;
    color: #2d2d2d;
    line-height: 1.4;

    @media (min-width: 26rem) {
        width: calc(100% - 8rem);
    }
`;

export const form = css`
    & label[for='bot-field'] {
        display: none;
    }
`;

export const label = css`
    margin: 1px 0 1rem;
    align-items: center;

    @media (min-width: 26rem) {
        display: flex;
    }

    span {
        display: block;
        flex: 0 0 8rem;
        color: #ffb300;
        font-weight: 700;
        padding-right: 1rem;

        @media (min-width: 26rem) {
            text-align: right;
        }
    }

    input,
    textarea {
        display: block;
        width: 100%;
        flex: 1 1 70%;
        border-radius: 0.3rem;
        border: 2px solid #ffb300;
        padding: 0.5rem;
        transition: border 0.3s;
        font-family: inherit;

        &:focus {
            border-color: #388e3c;
            outline: none;
        }
    }

    textarea {
        min-height: 6rem;
    }
`;

export const submitWrapper = css`
    max-width: 16rem;
    margin-left: auto;
`;

export const spin = keyframes`
from {
    transform: rotate(0);
}

to {
    transform: rotate(360deg);
}
`;

export const spinner = css`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: '';
        box-sizing: border-box;
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        border: 4px solid rgba(255, 255, 255, 0.4);
        border-top-color: rgba(255, 255, 255, 1);
        border-radius: 1rem;
        animation: ${spin} 0.7s linear infinite;
    }
`;
